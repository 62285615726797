<template>
  <div class="cmp-favorites-control">

    <a v-if="(!isFavoriteComputed && !touched) || (touched && !isFavorite)"
      @click="addToFavorites()"
      class="favorites-icon add-to">
      <!-- BESPOKE TAL: Changed heart icon to list -->
      <i class="fa fa-list-ul" aria-hidden="true"></i>      
      <!-- END BESPOKE TAL -->

      <!-- BESPOKE PAL: Changed favorites tanslation to orderlist translation -->
      <span v-if="useDescription" v-translation="{ type: 'button', code: 'button_add_to_order_list' }" class="add-to-desc"></span>      
      <!-- BESPOKE PAL: Changed favorites tanslation to orderlist translation -->
    </a>

    <a v-else
      @click="removeFromFavorites()"
      class="favorites-icon remove">
      <!-- BESPOKE TAL: Changed heart icon to list -->
      <i class="fa fa-list-ul" aria-hidden="true"></i>
      <!-- END BESPOKE TAL -->
      <!-- BESPOKE PAL: Changed favorites tanslation to orderlist translation -->
      <span v-if="useDescription" v-translation="{ type: 'button', code: 'button_remove_from_order_list' }" class="remove-desc"></span>
      <!-- END BESPOKE PAL -->
    </a>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
export default {
  props: {
    useDescription: { type: Boolean, required: false, default: false },
    product: { type: Product, required: true }
  },
  data () {
    return {
      touched: false,
      isFavorite: false
    };
  },
  computed: {
    ...mapGetters(['addProductToListEndpoint', 'removeProductFromListEndpoint', 'customerId', 'clientCode']),
    isFavoriteComputed () {
      // BESPOKE TAL
      // Changed favorites to orderlist
      return this.product.includedInCustomerLists.includes('orderlist');
      // END BESPOKE TAL
    }
  },
  methods: {
    addToFavorites () {
      // BESPOKE TAL
      // Changed favorites to orderlist
      const endpoint = `${this.addProductToListEndpoint}?productCode=${this.product.id}&debtorId=${this.customerId}&listId=orderlist`;
      // END BESPOKE TAL
      axios.post(endpoint).then(res => {
        this.touched = !this.touched;
        this.isFavorite = true;
      });
    },
    removeFromFavorites () {
      // BESPOKE TAL
      // Changed favorites to orderlist
      const endpoint = `${this.removeProductFromListEndpoint}?productCode=${this.product.id}&debtorId=${this.customerId}&listId=orderlist`;
      // END BESPOKE TAL
      axios.delete(endpoint).then(res => {
        this.touched = !this.touched;
        this.isFavorite = false;
      });
    }
  },
  created () {
    // BESPOKE TAL
    // Changed favorites to orderlist
    this.isFavorite = this.product.includedInCustomerLists.includes('orderlist');
  }
}
</script>

<style>

</style>
